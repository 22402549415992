<style lang="less" scoped>



.infoBox {
    // min-height: 480px;
    background: #F79421;
    color: #fff;
    padding: 3% 5%;
    box-sizing: border-box;
}
.mainBox {
    overflow:hidden;
    padding-top: 80px;
    padding-bottom: 195px;
}
.width70 {
    width: 70%;
    padding: 0 0 0 5%;
    h1 {
        margin: 0 0 10px;
    }
}
.width30 {
    width: 30%;
}
.indexLogo {
    display: flex;
    transform: translate(-35px, -35%);
    justify-content: center;
    // border: solid red 1px;
    width: 120%;
    height: 95px;
    position: relative;
    img {
        width: 100%;
        height: auto !important;
        position: absolute;
        bottom: -18px;
        left: 0;
    }
}
.logoTextWrap {
    p{
        margin: 0;
        width: 120%;
        padding-left: 5%;
        line-height:28px;
    }
    >p:last-child {
        font-size: 18px;
        word-spacing: 8px;
        
    }
    >p:first-child {
        letter-spacing: 0.8em;
        font-size:24px;
    }
}
.video {
    height: 280px;
    width: 100%;
    background: #000;
    margin-top: 30px;
    margin-bottom: 10px;
}
.logo2 {
    width: 110%;
    // margin-top: -20%;
    transform: translate(-76px, -60px);
}


.contentWrap {
    width: calc(100% + 20px);
    transform:translateX(-10px);
    color: #FFF;
    >div:first-child {
        width:calc(32% + 10px);
        >div {
            height: 195px;
            background: rgba(97, 131, 204,0.8);
            margin-left:10px;
            margin-top:10px;
            cursor: pointer;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            &:hover {
                opacity:0.8;
            } 
        }
    }
    >div:last-child {
        width: 67%;
        >div {
            width: calc(50% - 10px);
            float: left;
            height: 195px;
            background: rgba(97, 131, 204,0.8);
            margin-left:10px;
            margin-top:10px;
            cursor: pointer;
            transition: 0.3s;
            position: relative;
            overflow: hidden;
            &:hover {
                opacity:0.8;
            } 
        }
    }
}
.contentBox {
    background: transparent!important;;
    h1 {
        font-size:40px;
        margin: 0;
    }
}
.smallLogo {
    width: 40%;
    height: 100px;
    display: block;
    margin: auto;
}
.yellowBg {
    padding: 0 7% ;
    background: #F79420 !important;;
    >img {
        width: 75%;
        display: block;
        margin: 50px auto 0;
    }
}
.contentItem {
    >p {
        padding-left: 7%;
        font-size: 16px;
        position: relative;
        z-index:1;
        width: 70%;
    }
    position: relative;
    overflow: hidden;
    >img {
        position: absolute;
        width: 100%;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        // opacity: 0.5;
    }
}

.w7 {
    width: 65%;
}
.w3 {
    width: 35%;
}
.aText {
    background: #637FB7;
    padding: 15px;
    box-sizing: border-box;
    padding-right: 45%;
    h2 {
        margin: 0 0 0.5em;
    }
    
}
.comIntro {
    width: 60%;
    background: #29AAE1;
    color: #FFF;
    padding: 1em;
    box-sizing: border-box;
    h2 {
        margin: 0 0 0.6em;
    }
}
.companyImg {
    width: 40%;
    background-image: url('~@/assets/img/companyImg-1.png');
    background-size:cover !important;
    background-repeat: no-repeat !important;
    background-position:50% 50% !important;
}
.manBox {
    padding: 1em 0.5em;
    background: #F79421;
    margin-left: 0.5em;
    >h4 {
        text-align:right;
        font-size: 20px;
        margin: 20px 0 29px;
        padding-bottom: 38px;
    }
    >div {
        background: #273C70;
        padding: 0.5em;
        margin-bottom: 2.7em;
        text-align: right;
    }
    >div:last-child {
        margin: 0;
    }
}
.man{
    position: relative;
    >img {
        width: 35%;
        position: absolute;
        left:0;
        bottom: -1em;
    }
    >div {
        width: 70%;
        text-align: right;
    }
    p {
        margin: 0;
    }
}
.man1 {
    width: 35% !important;
    bottom: 2px !important;
}
.mask3 {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:100;
    background: rgba(0,0,0, 0.5);
    animation: an1 linear 0.5s;

}
.mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:100;
    background: rgba(0,0,0, 0.5);
    p {
        margin: 0;
        width: 300px;
        white-space: pre-wrap;
    }
    img {
        height:300px;
        width: auto;
        display: block;
        margin: auto;
    }
    animation: an1 linear 0.5s;
    >div {
        animation: an2 0.3s;
        padding: 30px;
        width: 500px;
        height: 350px;
        background-size: cover !important;
        // border: solid red 1px;
        border-radius: 10px;
        overflow:hidden;
    }
}
.mask2 {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index:100;
    background: rgba(0,0,0, 0.5);

    img {
        height:auto;
        width: 60vw;
        display: block;
        margin: auto;
        border: solid #FFF 2px;
    }
    animation: an1 linear 0.5s;
}
@keyframes an1 {
    from{
        opacity: 0;
    }
    to{
        opacity:1
    }
}
@keyframes an2 {
    from{
        transform:translateY(-500px);
    }
    to{
        transform:translateY(0);
    }
}
.teamImg {
    height: 28px;
    position: relative;
    >img {
        position: absolute;
        left: 55%;top: 0;
        transform:translate(-50%);
        width: 33%;
        z-index: 2;
    }
}
.gridTable {
    width: calc(100% + 10px);
    transform: translateX(-10px);
    // display: grid;
    // display: -ms-grid;
    // -ms-grid-columns: 34% 1fr;
    // grid-template-columns: 34% 1fr;
    p {
        margin: 0;
    }
    >div {
        box-sizing: border-box;
        margin-left: 10px;
        margin-top: 10px;
        height: 218px;
        overflow: hidden;
        float: left;
    }
}
.w1 {
    width: 33%;
}
.w2 {
    width: 63%;
}
.cBox1 {
    background: #362572;
    padding: 2em 1em;
    >img {
        width: 50%;
        margin: 0 0 10px -10px;
    }
}
.cBox2 {
    background:#118DCC;
    padding: 2em 1em;
    >img {
        width: 50%;
        margin: 0 0 10px -10px;
    }
}
.ml10 {
    margin-left: 10px;
}

.culBox {
    float: left;
    width: 32.3%;
    margin-right: 10px;
    margin-top: 10px;
    height: 195px;
    background-size:cover;
    background-position: center;
    text-shadow: 10px #000;
    >h3 {
        width: 50%;
        text-align:center;
        margin: 0;
        cursor: pointer;
        font-size: 25px;
        line-height: 32px;
        text-shadow: 3px 3px 5px #11205e, 3px 3px 5px #000 !important;
    }
}

.bg1 { background-image: url('~@/assets/img/culture1.jpg'); }
.bg2 { background-image: url('~@/assets/img/culture2.png'); }
.bg3 { background-image: url('~@/assets/img/culture3.png'); }
.newsWrap {
    width: calc(100% + 10px);
    transform:translateX(-10px);
    display: flex;
    >div:first-child {
        width: 33.33%;
    }
    >div:last-child {
        width: 66.66%;
    }
}
.newsItem {
    height: 100px;
    // border: solid red 1px;
    margin-left: 10px;
    margin-top: 10px;
    position:relative;
    overflow:hidden;
    transition: 0.3s;
    
    &:hover > img { opacity:0.8 }
    >img {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }
    >div {
        position: relative;
        z-index:1;
        background:#637FB7;
        height: 45%;
    }
}
.tRight { 
    text-align: right !important;
    position: relative;
    >img {
        position: absolute;
        width: 37%;
        left: 0;
        top: 20px;
    }
    >span {
        box-sizing: border-box;
        padding-right: 10px;
        // border: solid red 1px;
        display: block;
        width: 73%;

    }
 }
.height400 {
    height: 210px !important;
    // border: solid red 1px;
    
    box-sizing:border-box;
    margin-left: 10px;
    margin-top: 10px;
    position:relative;
    transition: 0.3s;
    cursor: pointer;
    >div{
        background: #27AAE1;
        padding: 2em;
        min-height: 100%;
        box-sizing: border-box;
    }
    li {
        padding: 8px 0;
    }
}
.grid2 {
    >div {
        width: 47.9%;
        float: left;
    }
}
.cBtn  {
    position: absolute;
    right:0;
    bottom: 10%;
    padding: 5px 10px;
}
.pipeWrap {
    h2 {  margin: 0;}
    transform: translateX(-10px);
    >div:first-child {
        >div {
            // border: solid red 1px;
            height: 195px;
            margin-left: 10px;
            margin-top: 10px;
            background: #637FB7;
        }
        >div:first-child {
            width: 64%;
        }
        >div:last-child {
            width: 36%;
        }
    }
    >div:last-child {
        >div {
            background: #637FB7;    
            width: 33.33%;
            height: 380px;
            margin-left: 10px;
            margin-top: 10px;
            // border: solid green 1px;
        }
    }
    >div>div {
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            opacity: 0.8;
        }
    }
}
.pImg1 {
    position: absolute;
    width: 100%;
    height: 100%;
    left:0px;
    top: 0;
    // border: solid red 1px;
    // transform: skew(-15deg);
    overflow:hidden;
    >img {
        width: 100%;
        height: 100%;
        // width:130% !important;
        // height: auto !important;
        // transform: skew(15deg) translateX(-5%);
    }
    
}
.pip1 {
    >h2 {
        position: absolute;
        bottom: 10%;
        right: 10%;
    }
}
.pipTitle {
    font-size: 20px;
    box-sizing: border-box;
    padding-left: 10px;
    
}
.pImg2 {
    width:100%;height:100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    overflow:hidden;
    >img {
        height: auto;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
.pipbottomBox {
    background: #F79420 !important;
    padding: 30px 15px 10px;
    box-sizing: border-box;
    opacity: 1 !important;
    >img:first-child {
        position: absolute;
        left: 0;
        top: 17px;
        width: 50%;
    }
    .lImg {
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: 85%;
    }
}
.equipment {
    transform: translateX(-10px);
    >div {
        float: left;
        width: calc(33.33% - 10px);
        margin-left:10px;
        margin-top: 10px;
        height: 195px;
        // border: solid red 1px;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;
        transition: 0.3s;
        >a {
            width: 100%;
            height: 100%;
            display: block;
            >img {
                height: 100%;
                width: 100%;
                display: block;
            }
        }
        
    }
    >div:hover {
        opacity: 0.9;
    }
}
.equLastBox {
    width: calc(66.6% - 10px) !important;
    background: #F79420;
    padding: 10px;
    &:hover {
        opacity: 1 !important;
    }
}
.equLastBox2 {
    background: #F79420;
    padding: 10px;
}
.clientWrap {
    transform: translateX(-10px);
    >div {
        float:left;
        width: calc(16.66% - 10px);
        height: 115px;
        // border: solid red 1px;
        box-sizing: border-box;
        margin-left: 10px;
        margin-top: 10px;
        >a {
            width: 100%;
            height: 100%;
            display: block;
            >img {
                height: 100%;
                width: 100%;
                display: block;
            }
        }
    }
}
.clientFirst {
    width: calc(33.3% - 10px) !important;
}
.size38 {
    font-size: 35px;
    margin: 0;
}
.custom {
    transform: translateX(-10px);
    >div {
        float: left;
        width: calc(33.33% - 10px);
        margin-left:10px;
        margin-top: 10px;
        height: 195px;
        // border: solid red 1px;
        box-sizing: border-box;
        transition: 0.3s;
        // padding: 10px;
        cursor: pointer;
        >p {
            position: relative;
            z-index:10;
            padding-left: 7%;
            width: 85%;
        }
    }
    >div:hover {
        opacity: 0.9;
    }
}
.imgA {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover !important;
    background-position: center !important;
    background-repeat:no-repeat;
    display:block;
    width: 100%;
    height: 100%;
    opacity: 0.7;
}
.posi1 {
    width: 38%;
    position: absolute;
    left: 0;
    top: 25px;
}
.blueBg {
    background: #00559F !important;
}
.iBox {
    width: 640px;
    position: relative;
    // background: #FFF;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    iframe {
        width: 100%;
        height: 380px;
        border: solid #FFF 2px;
        border-radius: 10px;
        background: #FFF;
    }
}
.hideX {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius:50%;
    background: #FFF;
    box-shadow: 0 0 10px #000;
    right: -15px;
    top: -15px;
    transition: 0.3s;
    cursor: pointer;
    >img {
        width: 50%;
        height: 50%;
        &:hover {
            opacity: 0.5 !important;
            transition: 0.3s;
        }
    }
}
.topList {
    width:100%;
    position: absolute;
    left: 50%;
    top: -45px;
    transform: translateX(-50%);
    >a {
        width: 37px;
        height: 37px;
        display: block;
        margin: 0 4px;
        transition: 0.3s;
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
    opacity: 0.95;
    transition: 0.3s;
    &:hover {
        opacity: 1 !important;
    }
}
.moreButton {
    background: #E3E6EC;
    color:#333;
    padding: 0 5px;
    display: inline-block;
    margin-top: 5px;
    cursor: pointer;
    user-select: none;
}
.manImg {
    position: absolute;
    width: 40%;
    right: -5%;
    top: -5vw;
    z-index: 1;
}
.mNewsBox {
    width: 80%;
    min-height: 20px;
    // border: solid red 1px;
    margin: auto;
}
.mHei {
    background-size: auto 100% !important;
    background-position: 0 0 !important;
}
.mobileBoxWrap {
    width: 80%;
    margin: auto;
}
.mobileBox {
    transition: 0.3s;
    margin-top: 10px;
    overflow: hidden;
    height:55vw;
    position: relative;
    background: rgba(97, 131, 204, 0.8);
    >img {
        position: absolute;
        width: 100%;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        // opacity: 0.5;
    }
    >p {
        padding-left: 7%;
        font-size: 16px;
        position: relative;
        z-index:1;
    }
}
li {
    list-style:disc;
}
.eBimg {
    width: 70%;
    height:30%;
    // border: solid red 1px;
}
.widthAdd10 {
    width: calc(100% + 10px);
}
.mClients {
    p {
        position: relative;
        z-index: 100;
        margin-left: 10px;
    }
}
strong {
    line-height: 1.1em;
}
.w6 {
    // border: solid red 1px;
    float: left;
    width: calc(64% - 10px);
}

.whiteBg {
    background: #FFF;
    transition: 0.3s;
    &:hover {
        opacity: 0.8;
    }
}
// .ho {
//     white-space:nowrap;
//     overflow:hidden;
//     text-overflow:ellipsis;
// }
</style>

<template>
    <div>    
        <div class="mShow topImg1">
            <img src="@/assets/img/topLogo.png" alt="">
        </div>
        <div class="container whiteColor">
            <!-- 公司简介 + 视频 + news -->
            <br><br>
            <div class="mt80"></div>
            <div class="mainBox " id="index" style="overflow:visible !important;">
                
                <div class="infoBox relative" style="overflow:visible !important;">
                    <div class="ju">
                        <div class="width30 mNone">
                            <div class="indexLogo">
                                <!-- <img :src="info.topLogo" /> -->
                                <!-- <img src="@/assets/img/mans.png" /> -->
                            </div>
                            <img class="logo2" src="@/assets/img/logo2.jpg" alt="">

                        </div>
                        <div class="width70">
                            <strong class="size35 mSize30">Waterland</strong>
                            <p>
                                The establishment of the Waterland Group is originated from three branches including the Waterland Detection Engineering Limited, 
                                Waterline Technology Engineering Limited and the Waterland Technology Development Limited. They are incorporated to provide services ranging from product selling to site work operation. 
                                The Group has been boosting up in recent years which enriching its professional skills in the industry.
                            </p>
                            
                        </div>
                    </div>

                    <div>
                        <iframe class="video" :src="info.video" 
                            scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
                    </div>


                    
                    <img class="manImg mShow" src="@/assets/img/logo2.jpg" />
                    <!-- <img class="manImg mShow" :src="info.topLogo" /> -->


                    
                </div>
                

                <!-- news -->
                <div class="newsWrap mNone">
                    <div>
                        <div class="newsItem">
                            <img src="@/assets/img/loginBg.png" @click="showImg=true">
                            <div class="tRight al flex-end">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Services Map</span>
                            </div>
                        </div>
                        <div class="height400 relative">
                            <div class="relative">
                                <img src="@/assets/img/line.png" class="posi1">
                                <div class="flex-end" style="transform:translateY(-5px)">
                                    
                                    <span>Events</span>
                                </div>
                                <ul class="whiteColor ">
                                    <li class="op ho" v-for="(item) in info.news.slice(0,4)" :key="item.id" @click="toNews(item)">{{item.title}}</li>
                                </ul>
                                <div class="ju" v-if="info.news.length==0">No Data</div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="grid2 clearBoth" >
                        <div class="newsItem">
                            <img src="@/assets/img/dect.png"  @click="scrollPage({key: 'dectection'})">
                            <div class="tRight al flex-end blueBg" >
                                <img src="@/assets/img/line.png" alt="">
                                <span>Waterland Detection Engineering Ltd</span>
                            </div>
                        </div>
                        <div class="newsItem">
                            <img src="@/assets/img/Media.png" alt=""  @click="showIfr=true">
                            <div class="tRight al flex-end blueBg">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Media</span>
                            </div>
                        </div>
                        <div class="newsItem">
                            <img src="@/assets/img/pipe.png" alt=""  @click="scrollPage({key: 'pipe'})">
                            <div class="tRight al flex-end">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Waterline Technology Engineering Ltd</span>
                            </div>
                        </div>
                        <div class="newsItem">
                            <img src="@/assets/img/clients.png" alt="" @click="scrollPage({key: 'client'})">
                            <div class="tRight al flex-end blueBg">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Clients</span>
                            </div>
                        </div>
                        
                        <div class="newsItem">
                            <img src="@/assets/img/eq.png" alt=""  @click="scrollPage({key: 'equipment'})">
                            <div class="tRight al flex-end">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Waterland Technology Development Ltd</span>
                            </div>
                        </div>
                        <div class="newsItem">
                            <img src="@/assets/img/cul.png" alt=""  @click="scrollPage({key: 'culture'})">
                            <div class="tRight al flex-end">
                                <img src="@/assets/img/line.png" alt="">
                                <span>Company Culture</span>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- 移动端新闻部分 -->
                <div class="mNewsBox mShow">
                    <div class="newsItem">
                        <img src="@/assets/img/loginBg.png" @click="showImg=true">
                        <div class="tRight al flex-end">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Services Map</span>
                        </div>
                    </div>
                    <div class="newsItem">
                        <img src="@/assets/img/dect.png"  @click="scrollPage({key: 'dectection'})">
                        <div class="tRight al flex-end blueBg" >
                            <img src="@/assets/img/line.png" alt="">
                            <span>Waterland Detection Engineering Ltd</span>
                        </div>
                    </div>
                    <div class="newsItem">
                        <img src="@/assets/img/Media.png" alt=""  @click="showIfr=true">
                        <div class="tRight al flex-end blueBg">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Media</span>
                        </div>
                    </div>
                    <div class="newsItem">
                        <img src="@/assets/img/pipe.png" alt=""  @click="scrollPage({key: 'pipe'})">
                        <div class="tRight al flex-end">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Waterline Technology Engineering Ltd</span>
                        </div>
                    </div>
                    <div class="newsItem">
                        <img src="@/assets/img/clients.png" alt="" @click="scrollPage({key: 'client'})">
                        <div class="tRight al flex-end blueBg">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Clients</span>
                        </div>
                    </div>
                    
                    <div class="newsItem">
                        <img src="@/assets/img/eq.png" alt=""  @click="scrollPage({key: 'equipment'})">
                        <div class="tRight al flex-end">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Waterland Technology Development Ltd</span>
                        </div>
                    </div>
                    <div class="newsItem">
                        <img src="@/assets/img/cul.png" alt=""  @click="scrollPage({key: 'culture'})">
                        <div class="tRight al flex-end">
                            <img src="@/assets/img/line.png" alt="">
                            <span>Company Culture</span>
                        </div>
                    </div>
                    <div class="height400 relative">
                        <div>
                            <img src="@/assets/img/line.png" class="posi1">
                            <div class="flex-end" style="transform:translateY(-5px)">
                                
                                <span>Events</span>
                            </div>
                            <ul class="whiteColor ">
                                <li class="op ho" v-for="(item) in info.news.slice(0,5)" :key="item.id" @click="toNews(item)">{{item.title}}</li>
                            </ul>
                        </div>
                        
                    </div>


                </div>
            </div>

            <!-- about us -->
            <div class="mainBox" id="aboutUs">
                <div class="ju teamImg mNone">
                    <img :src="info.AboutUsLogo" alt="">
                </div>
                <div class="flex mNone">
                    <div class="w7">
                        <div class="aText whiteColor">
                            <h2>Mission</h2>
                            <div v-html="info.Mission"></div>
                        </div>
                        <div class="flex">
                            <div class="comIntro">
                                <h2>Company Introduction</h2>
                                <div v-html="info.Intro"></div>
                            </div>
                            <div class="companyImg" :style="{backgroundImage:`url(${info.body_image})`}"></div>
                        </div>
                    </div>
                    <div class="w3">
                        <div style="height:185px;text-align:right;">
                            <h1 class="size40 mSize30" style="margin: 0;">About us</h1>
                        </div>
                        <br><br>
                        <div class="manBox ">
                            <h4>Management Team</h4>
                            <div v-for="(item,i) in mans" :key="i" class="al man flex-end">
                                <img :src="item.img" alt="" :class="[{man1:i==0}]">
                                <div>
                                    <p>{{item.name}}</p>
                                    <p>{{item.desc}}</p>
                                    <a @click="moreInfo(item)" class="moreButton bold">More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 移动端 -->
                <div class="mShow">
                    <div style="height:80px;text-align:right;">
                        <h1 class="size40 mSize30" style="margin: 0;">About us</h1>
                    </div>
                    <div>
                        <div class="aText whiteColor">
                            <h2>Mission</h2>
                            <div v-html="info.Mission"></div>
                        </div>
                        <div class="flex">
                            <div class="comIntro">
                                <h2>Company Introduction</h2>
                                <div v-html="info.Intro"></div>
                            </div>
                        </div>
                    </div>
                    <div class="manBox ">
                        <h4>Management Team</h4>
                        <div class="clearBoth">
                            <div v-for="(item,i) in mans" :key="i" class="al man flex-end">
                                <img :src="item.img" alt="">
                                <div>
                                    <p>{{item.name}}</p>
                                    <p>{{item.desc}}</p>
                                    <a @click="moreInfo(item)" class="moreButton bold">More</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>

            <!-- dectection -->
            <div class="mainBox " id="dectection">
                <div class="contentWrap ju mNone">
                    <div>
                        <div class="contentBox" style="line-height:40px;">
                            <h1>Detection</h1>
                            <h1>Survey</h1>
                        </div>
                        <div class="yellowBg" style="height: 400px;opacity: 1;">
                            <br>
                            <p>
                                Our company has been providing services in the industry for over 10 years. These includes the geophysical and geotechnical engineering surveys, water, sewer and drainage aspects. The diversity in services leads our company to move forward and explore more in the industry.
                            </p>
                            
                            <img src="@/assets/img/topLogo.png" alt="">

                        </div>
                    </div>

                    <div class="clearBoth">
                        <div v-for="(item,i) in content" :key="i" class="contentItem al" @click="toDect(item)">
                            <!-- <p>{{item.title}}</p> --> 
                            <img :src="item.preImg" alt="">
                        </div>

                        

                    </div>
                </div>
                <!-- 移动端dectection -->
                <div class="mShow">
                    <div class="mobileBoxWrap ">
                        <div class="contentBox" style="line-height:40px;">
                            <h1 class="mSize30" style="text-align:center;">Detection Survey</h1>
                        </div>
                        <div v-for="(item,i) in content" :key="i" class="mobileBox al" @click="toDect(item)">
                            <!-- <p>{{item.title}}</p> -->
                            <img :src="item.preImg" alt="">
                        </div>
                    </div>
                    <div class="yellowBg" style="height: 410px;">
                        <br>
                        <p>
                            Our company has been providing services in the industry for over 10 years. These includes the geophysical and geotechnical engineering surveys, water, sewer and drainage aspects. The diversity in services leads our company to move forward and explore more in the industry.
                        </p>
                        <br>
                        <img src="@/assets/img/topLogo.png" class="smallLogo" alt="">
                        <br>
                    </div>
                </div>
                
            </div>
            
            


            <!-- pipe -->
            <div class="mainBox" id="pipe">
                <div class="pipeWrap mNone">
                    <div class="ju pipeTop">
                        <div class="pip1 relative" v-if="pipe[0]" @click="toDect(pipe[0])">
                            <div class="pImg1"><img :src="pipe[0].preImg" alt=""></div>

                        </div>
                        <div v-else></div>
                        <div class="pipTitle size30" style="background: transparent;line-height: 30px;">
                            <h2>Pipe</h2>
                            <h2>Replacement</h2>
                            <h2>and</h2>
                            <h2>Rehabilitation</h2>
                        </div>
                    </div>
                    <div class="ju">
                        <div v-if="pipe[1]" class="pip2 relative" @click="toDect(pipe[1])">
                            <!-- <h2 style="margin: 10px;">{{pipe[1].title}}</h2> -->
                            <div class="pImg2">
                                <img :src="pipe[1].preImg" alt="">
                            </div>
                        </div>
                        <div v-else></div>
                        <div v-if="pipe[2]" class="pip2 relative" @click="toDect(pipe[2])">
                            <!-- <h2 style="margin: 10px;">{{pipe[2].title}}</h2> -->
                            <div class="pImg2">
                                <img :src="pipe[2].preImg" alt="">
                            </div>
                        </div>
                        <div v-else></div>
                        <div class="pipbottomBox relative" style="width: 38%">
                            <img src="@/assets/img/line.png" alt="">
                            <img class="lImg" src="@/assets/img/logo2.png" alt="">
                            <p>
                                With the concern on improving the underground situation, Waterline Technology Engineering Limited has been carrying out pipe rehabilitation services in order to maintain and upgrade the "health" of the pipes. With the use of trenchless technology, it definitely saves time on its process and, at the same time, lightened the destruction causes to the environment as it does not require any excavation being carried out.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 移动端pipe -->
                <div class="mShow">
                    <h2 class="pipeTitle" >
                        <strong class="mSize30">Pipe Replacement and Rehabilitation</strong>
                    </h2><br>
                    
                    <div class="pip1 relative" v-if="pipe[0]" @click="toDect(pipe[0])">
                        <div class="pImg1"><img :src="pipe[0].preImg" alt=""></div>
                        <!-- <h2>{{pipe[0].title}}</h2> -->
                    </div>
                    <div class="clearBoth" style="width: 105%;">
                        <div v-if="pipe[1]" class="pip2 relative" @click="toDect(pipe[1])">
                            <!-- <h2 style="margin: 10px;">{{pipe[1].title}}</h2> -->
                            <div class="pImg2">
                                <img :src="pipe[1].preImg" alt="">
                            </div>
                        </div>
                        <div v-if="pipe[2]" class="pip2 relative ml10" @click="toDect(pipe[2])">
                            <!-- <h2 style="margin: 10px;">{{pipe[2].title}}</h2> -->
                            <div class="pImg2">
                                <img :src="pipe[2].preImg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="pipbottomBox relative">
                        <img src="@/assets/img/line.png" alt="">
                        <img class="lImg" src="@/assets/img/logo2.png" alt="">
                        <p>
                            With the concern on improving the underground situation, Waterline Technology Engineering Limited has been carrying out pipe rehabilitation services in order to maintain and upgrade the "health" of the pipes. With the use of trenchless technology, it definitely saves time on its process and, at the same time, lightened the destruction causes to the environment as it does not require any excavation being carried out.
                        </p>
                    </div>

                </div>
            </div>
            


            <!-- equipment -->
            <div class="mainBox" id="equipment">
                <div class="equipment clearBoth mNone">
                    <div>
                        <h1 class="size40" style="line-height: 40px;margin: 0;">Equipment</h1>
                    </div>
                    <div v-for="(item) in equipment.image_list" :key="item.image">
                        <a :href="item.url">
                            <img :src="item.image">
                        </a>
                    </div>
                    
                    
                    <!-- <div v-for="(item) in equipment.image_list.slice(3)" :key="item.image">
                        <a :href="item.url">
                            <img :src="item.image">
                        </a>
                    </div> -->
                    <div class="equLastBox">
                        <span>
                          Our company aims to provide advanced technological products in order to introduce the best equipment to the industry for utility survey works. With a sense that the upgrading in both the quality and quantity of the underground facilities, the bonding with other brands becomes essential and this is what our company is able to provide. With these elements, we are confident to equip you with the best equipment!  
                        </span>
                        <div class="flex-end" style="margin-top: 10px;margin-right: 10px;">
                            <img class="eBimg" src="@/assets/img/logoText2.png" alt="">
                        </div>

                        
                    </div>
                </div>
                <!-- 移动端equipment -->
                <div class="mShow">
                    <h2 class="pipeTitle" >
                        <strong class="mSize30">Equipment</strong>
                    </h2>
                    <div>
                        <div class="clearBoth equipment">
                            <div v-for="(item) in equipment.image_list" :key="item.image">
                                <a :href="item.url">
                                    <img :src="item.image">
                                </a>
                            </div>
                        </div>
                        <div class="equLastBox2 mt10">
                            <span>
                            Our company aims to provide advanced technological products in order to introduce the best equipment to the industry for utility survey works. With a sense that the upgrading in both the quality and quantity of the underground facilities, the bonding with other brands becomes essential and this is what our company is able to provide. With these elements, we are confident to equip you with the best equipment!  
                            </span>
                            <div class="">
                                <br>
                                <img class="eBimg" src="@/assets/img/logoText2.png" alt="">
                            </div>

                            
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- client -->
            <div class="mainBox" id="client">
                <div class="clientWrap clearBoth mNone">
                    <div class="clientFirst">
                        <h1 class="size50" style="line-height: 50px;margin: 0;">Clients</h1>
                    </div>
                    <div v-for="(item) in client.image_list" :key="item.image">
                        <a :href="item.url"  class="whiteBg"><img :src="item.image" alt=""></a>
                    </div>
                </div>
                <!-- 移动端client -->
                <div class="mShow">
                    <h1><strong class="size40">Clients</strong></h1>
                    <div>
                        <div class="clearBoth widthAdd10">
                            <div v-for="(item,i) in client.image_list" :key="i" class="mClients">
                                <a :href="item.url" class="whiteBg"><img :src="item.image" alt=""></a>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>

            <!-- 自定義模板 -->
            <div>
                <div v-for="(item) in custom" :key="item.title" class="mainBox" :id="item.title">
                    <div class="custom clearBoth mNone">
                        <div>
                            <h1 class="size40" style="line-height: 40px;margin: 0;">{{item.title}}</h1>
                        </div>
                        <div v-for="(c) in item.content" :key="c.title" class="relative al" @click="toDect(c)">
                            <a class="imgA" :style="{background:`url(' ${c.content[0].image[0] ? c.content[0].image[0].url : ''} ')`}"></a>
                            <p>{{c.title}}</p>
                        </div>
                    </div>
                    <div class="mShow">
                        <h1><strong class="size40">{{item.title}}</strong></h1>
                        <div>
                            <div class="clearBoth widthAdd10">
                                <div v-for="(c) in item.content" :key="c.title" @click="toDect(c)" class="mClients relative">
                                    <a class="imgA" :style="{background:`url(' ${c.content[0].image[0] ? c.content[0].image[0].url : ''} ')`}"></a>
                                    <p>{{c.title}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ju" v-if="item.content.length===0">No Data</div>
                </div>
            </div>

            <!-- culture -->
            <div class="mainBox" id="culture">
                <div>
                    <div class="clearBoth">
                        <div class="culBox hAuto" style="line-height: 30px;text-align:center" >
                            <h1 style="margin: 0;font-size: 30px;">Company </h1>
                            <h1 style="margin: 0;font-size: 30px;">Culture </h1>
                            <br>
                        </div>
                        <div class="w6">
                            <p>
                                Waterland focuses on innovative ideas and delivers complete solution for satisfying the market demand. Our people strives for delivering our best endeavor for achieving the goal of the projects.Our technicians always had to learn and get familiar for new trenchless technologies from inspection surveys to pipe rehabilitation works. Their competence for project execution had been truly reflected by winning the trust from our principle client.
                            </p><br>
                            <p>
                                The people working in the company have to face different challenging arising from project execution and they still have professional manner to encounter difficulties in order to meet the customer’s satisfaction. Every years, the company will have new technical services newly introduced to our client and our company is willing to work together with our principle client by conducting different project trials.
                            </p><br>
                            <p>We are happy to grow with the client hand by hand.</p><br><br>
                        </div>
                        
                    </div>
                    <div class="clearBoth">
                        <div class="culBox ju al columns bg1" @click="toCul(1)">
                            <h3>Company Actives</h3>
                            <!-- <h3></h3> -->
                        </div>

                        <div class="culBox ju al columns bg2"  @click="toCul(2)">
                            <h3>Seminar and Conference</h3>
                        </div>

                        <div class="culBox ju al columns bg3" style="margin-right: 0;" @click="toCul(3)">
                            <h3>Community Support</h3>
                        </div>
                    </div>
                </div>
            </div>

            <!-- contact -->
            <div class="mainBox" id="contact">
                <div class="sb2 al2 mShow2">
                    <h1 style="margin: 0;font-size:40px;line-height: 40px;">Contact us</h1>
                    <div  @click="toContact">
                        <img src="@/assets/img/right.png" width="30" alt="">
                    </div>
                </div>
                <div class="gridTable clearBoth">
                    <div class="cBox1 columns w1">
                        <img src="@/assets/img/line.png" alt="">
                        <div v-html="info.contact1"></div>
                        <!-- <p>Waterland Detection Engineering Ltd.</p>
                        <p> Waterland Technology Development Ltd. </p><br>
                        <p>T : (852) 2636 6900</p>
                        <p>F : (852) 2636 6907</p><br>
                        <p>E : enquiry@waterland.com.hk.hk</p> -->
                    </div>
                    <div class="w2">
                        <iframe style="width:100%;height: 100%;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7379.083684047425!2d114.133329!3d22.370922!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f885357edb67%3A0x341cc8833b271040!2z6aaZ5riv6JG15raM5ZyL55Ge6LevODjomZ_mlrDosZDkuK3lv4M!5e0!3m2!1szh-TW!2sus!4v1460100901357" frameborder="0"></iframe>
                    </div>
                    <div class="cBox1 columns w1">
                        <img src="@/assets/img/line.png" alt="">
                        <div v-html="info.contact2"></div>
                        <!-- <p>Waterline Technology Engineering Ltd.</p><br>
                        <p>T : (852) 3583 1616</p>
                        <p>F : (852) 3583 1919</p><br>
                        <p>E : info@waterline.com.hk</p> -->
                    </div>
                    <div class="flex-end relative mNone w2">
                        <h1 style="margin: 0;font-size:40px;text-align:right;line-height: 40px;">Contact us</h1>
                        <button class="cBtn" @click="toContact">Contact us</button>
                    </div>
                    <div class="cBox2 columns w1">
                        <img src="@/assets/img/line.png" alt="">
                        <div v-html="info.contact3"></div>
                        <!-- <p>A : Flat 902, 9/F, Block A</p>
                        <p>Sun Fung Centre,</p>
                        <p>88 Kwok Shui Road,</p>
                        <p>Kwai Chung, N.T.</p> -->
                    </div>
                    <div class="ju al w2">
                        <!-- <img src="@/assets/img/shop.png" style="width:100%" alt=""> -->
                        <img :src="info.contactLogo" style="width:100%;height: 100%;" alt="">
                    </div>
                    
                </div>
            </div>

            
            
        </div>
        <div class="container">
            <myFooter></myFooter>
        </div>
        <!-- 一个man的详情 -->

        <div class="mask ju al whiteColor" v-if="showMan " @click="showMan=false">
            <div @click.stop.prevent="" style="margin-bottom: 100px;" class="al sb mHei" :style="{ background:`url(${man.bg})` }">

                <div v-if="man.name.includes('Yan')"></div>
                <div style="width: 61%" class="mw100" v-if="man.name.includes('Yan')">
                    <h2 style="margin: 20px 0 0;">{{man.name}}</h2>
                    <p style="width: 100%">{{man.desc}}</p>
                    <p style="width: 100%">{{man.desc2}}</p>
                </div>
                <div style="width: 50%" class="mw100" v-if="man.name.includes('So')">
                    <h2 style="margin: 0;">{{man.name}}</h2>
                    <p style="width: 100%">{{man.desc}}</p>
                    <p style="width: 100%">{{man.desc2}}</p>
                </div>

                <div  v-if="man.name.includes('Yan') == false"></div>
            </div>
            
        </div>
        <div v-if="showImg" class="mask2 ju al" @click="showImg=false">
            <img src="@/assets/img/loginBg.png" alt="">
        </div>
        <div class="mask3" v-if="showIfr" @click="showIfr=false">
            <div class="iBox" @click.stop.prevent="">
                <div class="topList ju">
                    <a href="http://www.facebook.com/sharer.php?s=100&p[url]=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&p[images][0]=https%3A%2F%2Fwww.waterland.com.hk%2Fwp-content%2Fuploads%2F2016%2F04%2Fmedia-1.png&p[title]=WaterLand">
                        <img src="@/assets/img/fb.png" title="Facebook" />
                    </a>
                    <a href="https://plus.google.com/share?url=https://www.waterland.com.hk/#foobox-1/0">
                        <img src="@/assets/img/google.jpg" title="Google+" />
                    </a>

                    <a href="https://twitter.com/share?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&text=WaterLand">
                        <img src="@/assets/img/twitter.jpg" title="Twitter" />
                    </a>
                    <a href="https://pinterest.com/pin/create/bookmarklet/?media=https%3A%2F%2Fwww.waterland.com.hk%2Fwp-content%2Fuploads%2F2016%2F04%2Fmedia-1.png&url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&is_video=true&description=WaterLand">
                        <img src="@/assets/img/pinterest.jpg" title="Pinterest" />
                    </a>
                    <a href="http://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&title=WaterLand">
                        <img src="@/assets/img/in.png" title="Linkedin" />
                    </a>
                    <a href="http://bufferapp.com/add?text=WaterLand&url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0">
                        <img src="@/assets/img/buffer.png" title="Buffer" />
                    </a>
                    <a href="http://digg.com/submit?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&title=WaterLand">
                        <img src="@/assets/img/digg.jpg" title="Digg" />
                    </a>
                    <a href="http://www.tumblr.com/share/link?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&name=WaterLand">
                        <img src="@/assets/img/tumblr.jpg" title="Tumblr" />
                    </a>
                    <a href="http://reddit.com/submit?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&title=WaterLand">
                        <img src="@/assets/img/reddit.jpg" title="Reddit" />
                    </a>
                    <a href="http://www.stumbleupon.com/submit?url=https%3A%2F%2Fwww.waterland.com.hk%2F%23foobox-1%2F0&title=WaterLand">
                        <img src="@/assets/img/st.jpg" title="StumbleUpon" />
                    </a>
                </div>
                <div class="hideX ju al" @click="showIfr=false">
                    <img src="@/assets/img/close.png" alt="">
                </div>
                <iframe src="http://www.youtube.com/embed/xbujU0mRnvI?rel=0"  frameborder="0"></iframe>
                <!-- <iframe src="//player.bilibili.com/player.html?aid=416334953&bvid=BV1bV411q7qC&cid=285444235&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe> -->
            </div>
        </div>

    </div>
</template>

<script>
// import nav from "@/router/routes.ts"
import man1 from "@/assets/img/m1.png"
import man2 from "@/assets/img/m2.png"
export default {
    data () {
        return {
            drawer: false,
            info: {
                news: []
            },
            content: [],
            pipe: [],
            //about us 的两个人
            mans: [
                { 
                    name: "K.K.Yan",
                    img:require("@/assets/img/man1.png"),
                    // bg: "http://www.waterland.t3webspace.com/wp-content/uploads/2016/12/kkbackground3.png",
                    bg: man1,
                    desc: "Founder and Managing Director",
                    desc2: "BEng graduated from the Hong Kong University of Science and Technology, he has over 11 years of broad and extensive experience in utility industries particular for field works planning, project management, field monitoring works for network model calibration, leakage detection & monitoring, condition surveys, utility surveys, flow monitoring, underground void detection etc.He is also acting as Director of China Hong Kong Society of Trenchless Technology and responsible for promoting the trenchless technologies for the beneficial of Hong Kong Construction Industry."
                },
                { 
                    name: "Jacky So",
                    img:require("@/assets/img/man2.png"),
                    
                    // bg: "http://www.waterland.t3webspace.com/wp-content/uploads/2016/05/jackybackground.png",
                    bg: man2,
                    desc: "General Manager",
                    desc2: "He is Professional Civil Engineering graduated from University of Manchester in UK. His profession is Hydrologic Measurement and responsible for management of government Contract of Drainage Services Department of HKSAR for riverbed survey, flow discharge survey and development of index velocity rating."
                },
            ],
            man: {},
            showMan: false,
            showImg: false,
            showIfr: false,
            equipment: {
                content: "",
                image_list: [],
                title: ""
            },
            client: {}, 
            custom: [],
        }
    },
    created () {
        // this.nav = nav
        this.getData()
        this.getContent()
    },
    computed: {
        nav: {
            get () { return this.$store.state.nav },
            set (val) {
                this.$store.commit("setState", { key: "nav" , value: val })
            }
        },
        bg: {
            get () { return this.$store.state.bg },
            set (val) {
                // console.log(123,val)
                // this.$store.commit("setState", { key: "bg" , value: val })
                // console.log(this.$store.state.bg)
            }
        },
    },
    methods:{
        toCul (item) {
            this.$router.push("/culture?index=" + item)
        },
        toContact () {
            this.$router.push("/contact")
        },
        // 去dectection詳情
        toDect (item) {
            this.$router.push({
                name: "dectection",
                query: {
                    uuid: item.uuid
                }
            })
        },
        //去news详情
        toNews (item) {
            this.$router.push({
                name: "news",
                query: {
                    uuid: item.uuid
                }
            })
        },
        //点击about us 的人的more
        moreInfo (item) {
            this.man= item
            this.showMan = true
        },
        scrollPage (item) {
            let ele = document.getElementById(item.key)
            console.log(ele.offsetTop)
            window.scrollTo({
                top: ele.offsetTop,
                behavior: "smooth"
            })
        },
        change (e) {
            // console.log(e)
            // this.lang.setLang(e)
        },
        getData () {
            window.http({
                url: "index",
                method:"GET",
                
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    this.info = res.data.data
                    res.data.data.news.forEach(item => {
                        if (item.big_image) {
                            item.big_image = item.big_image.slice(1,item.big_image.length-1).split(";")
                        } else {
                            item.big_image = []
                        }
                    })
                    this.$store.commit("setState", { key: "bg" , value: res.data.data.bg })
                    
                } else {
                    this.info = {}
                }
            }).catch(e => {
                console.log(e)
                this.info = {}

            })
        },
        getContent () {
            window.http({
                url: "content",
                params: {
                    client: "client"
                }
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    let menu = []
                    let custom = []
                    res.data.data.data.forEach(item => {
                        item.content.forEach(c => {
                            c.content = JSON.parse(c.content)
                        })

                        
                        //dectection
                        if (item.id == 4) {
                            item.content.forEach(c => {
                                if (Boolean(c.img)) {
                                    try { c.preImg = JSON.parse(c.img).image } catch {}
                                }
                            })
                            this.content = item.content.slice(0,6)
                            menu.push({ name:item.title,key: "dectection" })
                        }
                        //pipe
                        if (item.id== 3) {
                            item.content.forEach(c => {
                                if (Boolean(c.img)) {
                                    try { c.preImg = JSON.parse(c.img).image } catch { console.log("error") }
                                }
                            })
                            this.pipe = item.content.slice(0,6)
                            menu.push({ name:item.title,key: "pipe" })
                        }
                        //equipment
                        if (item.id== 2) {
                            this.equipment = item.content[0].content
                            // menu.push({ name:item.title, key: "equipment" })
                            // console.log(this.equipment)
                        }
                        //client
                        if (item.id == 1) {
                            this.client = item.content[0].content
                            // menu.push({ name:item.title, key: "client" })
                            // console.log(this.client)
                        } 
                        if (item.id != 1&&item.id != 2 && item.id != 3&&item.id != 4) {
                            // console.log(item)
                            menu.push({ name: item.title, key: item.title})
                            custom.push(item)
                        }
                        

                    })

                    // console.log(custom)
                    this.custom = custom.slice(0,8)
                    // menu.reverse()
                    // //每次修改菜单前先重置
                    // this.nav = [
                    //     { name: "HOME",key:"index" },
                    //     { name: "ABOUT US",key:"aboutUs" },

                    //     { name: "EQUIPMENT",key:"equipment" },
                    //     { name: "CLIENTS",key:"client" },
                    //     { name: "CULTRUE", key: "culture" },
                    //     { name: "CONTACT US" , key: "contact"},
                    // ]
                    // menu.forEach(item => {
                    //     this.nav.splice(2,0,item)
                    // })
                    // console.log(this.custom);
                    
                } else {
                    this.content = []
                }
            }).catch((e) => {
                console.log(e)
                this.content = []
            })
        },
    }
    
}
</script>

